// api.js
import axios from 'axios';
import store from '@/store';
import AuthService from './authService';

const baseURL =
  process.env.VUE_APP_API_BASE_URL || 'http://localhost:5005/api/';

const API = axios.create({ baseURL });

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('user-token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    store.dispatch('loading/startLoading');
    return config;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => {
    store.dispatch('loading/stopLoading');
    return response;
  },
  async (error) => {
    store.dispatch('loading/stopLoading');
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await AuthService.refreshTokens();
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('user-token')}`;
        return API(originalRequest);
      } catch (refreshError) {
        AuthService.logout();
        return Promise.reject(refreshError);
      }
    }
    if (error.response && error.response.status === 401) {
      console.error(
        'Unauthorized. You do not have permission to perform this action.'
      );
    }
    return Promise.reject(error);
  }
);

API.getRoles = () => API.get('/roles');
API.addRole = (data) => API.post('/roles', data);
API.deleteRole = (roleId) => API.delete(`/roles/${roleId}`);
API.updateRolePermissions = (data) => API.put('/roles/permissions', data);
API.getPermissions = () => API.get('/permissions');
API.getRolePermissions = (roleId) =>
  API.get(`/permissions/${roleId}/permissions`);
API.assignPermission = (roleId, permissionId) =>
  API.post('/permissions/assign', { roleId, permissionId });
API.unassignPermission = (roleId, permissionId) =>
  API.post('/permissions/unassign', { roleId, permissionId });
API.getTeachers = () => API.get('/users/teachers');
API.getChildren = () => API.get('/users/children');
API.addChildrenToCohort = (cohortId, childIds) =>
  API.post(`/cohorts/${cohortId}/add-children`, { childIds });

export default API;
