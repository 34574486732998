export default {
  "systemNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важно: В настоящее время мы проводим обслуживание системы для улучшения производительности. Возможны кратковременные перебои в работе во время улучшения сервиса. Благодарим за понимание."])},
  "activityParticipants.slotTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время слота"])},
  "activityParticipants.bookingRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование отклонено"])},
  "Locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локации"])},
  "Teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учителя"])},
  "Cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когорты"])},
  "noFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры не применены"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить все"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локации"])},
  "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учителя"])},
  "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск локаций..."])},
  "searchTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск учителей..."])},
  "searchCohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск когорт..."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ничего"])},
  "selectedFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранные фильтры"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбрано"])},
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упрощение школьного администрирования"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать на портал SchoolPeak!"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя или Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверное имя пользователя или пароль"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать администратора"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление напоминаниями"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
  "reminderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление напоминаниями"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите язык"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот пароль будет использоваться для входа. Убедитесь, что он надежен. Администраторы могут устанавливать и изменять пароли, но пользователи могут изменять их после входа. Администраторы не могут просматривать пароли пользователей."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных слотов"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите временные слоты"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторяющееся бронирование"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите забронировать этот слот на каждую неделю?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль пользователя"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить пользователя"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить черновик"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить изменения"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль успешно обновлен"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш email"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить ссылку для сброса"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email обязателен"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка для сброса отправлена! Проверьте ваш email."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить ссылку для сброса. Попробуйте снова."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email и пароль обязательны"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все поля обязательны для создания администратора"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали оплаты"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать пользователя"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Защитите ваш аккаунт"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите новый пароль для восстановления доступа"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите пароль"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль обязателен"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен быть не менее 8 символов"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну цифру"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль слишком слабый"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно сброшен"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сбросить пароль"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к входу"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления об отмене сессий"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить уведомления об отмене сессий"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о переносе сессий"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить уведомления о переносе сессий"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки успешно сохранены"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить профиль"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать детали оплаты"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить детали оплаты"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить пароль"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые пароли не совпадают."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль успешно обновлен."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить пароль."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия добавлена"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая сессия успешно добавлена."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия восстановлена"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия восстановлена и будет проходить по плану."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о заметках сессии"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления при добавлении или обновлении заметки для сессии."])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта сессия уже забронирована. Пожалуйста, выберите другую."])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование успешно отменено."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление о новом сообщении"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления по email при получении нового сообщения."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о напоминаниях"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления о новых напоминаниях, назначенных вам или вашей группе."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления о новых активностях"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления о новых активностях для вашего ребенка."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОК"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбрать"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немецкий"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Латышский"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все активности"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление локациями"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление группами"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что нового"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внеклассные занятия"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навигация"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать активности"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите локацию"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите учителя"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите группу"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к деталям"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утверждения"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление праздниками"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На данный момент нет ожидающих бронирований."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите забронировать ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите забронировать"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["для"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать всех детей"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Для вашего сведения, цена за активность составляет ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цена за сессию составляет ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Платеж будет добавлен к вашему ежемесячному счету."])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я согласен', стоимость сессии будет добавлена к вашему ежемесячному счету."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в месяц"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в год"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование подтверждено"])},
  "confirmSlotBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить бронирование слота"])},
  "slotDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пожалуйста, подтвердите бронирование для следующего слота: Дата: ", _interpolate(_named("date")), ", Время начала: ", _interpolate(_named("startTime")), ", Время окончания: ", _interpolate(_named("endTime"))])},
  "slotBookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование слота подтверждено!"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот слот уже забронирован. Пожалуйста, выберите другое доступное время."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Активность '", _interpolate(_named("activity")), "' успешно забронирована."])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ежеквартально"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз в полгода"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всего"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я согласен"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои данные профиля"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать профиль"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие активности"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие активности"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["далее"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение обязательства"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я согласен', стоимость активности будет добавлена к вашему ежемесячному счету на следующие 3 месяца."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я согласен', стоимость активности будет добавлена к вашему годовому счету."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я согласен', стоимость активности будет разделена на ежеквартальные платежи."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я согласен', стоимость активности будет разделена на полугодовые платежи."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы нажмете 'Я согласен', полная стоимость активности будет списана сразу."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать по ребенку"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так. Пожалуйста, попробуйте позже."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность забронирована, ожидает утверждения."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы хотите отменить бронирование ", _interpolate(_named("activityName")), " для ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить действие"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите продолжить это действие?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование отменено."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временные слоты"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на странице"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление ролями"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы видите активности"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет предстоящих сессий"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши следующие события"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши открытые бронирования"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ребенок:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить бронирование"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отмену"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планировщик"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите причину"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите вашу причину"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу причину здесь..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больничный"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вид"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата бронирования"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтровать активности"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все активности"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прошедшие активности"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие активности"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будущие активности"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите фильтр"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по имени"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О занятии"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессии"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локация"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учителя"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие сессии"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прошедшие сессии"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка деталей активности..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенесено"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировано"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планировщик"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хаб"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все дети"])},
  "selectChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите ребенка"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В настоящее время нет активностей для ", _interpolate(_named("childName")), ". Пожалуйста, проверьте позже."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
  "navbar.messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщения"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои напоминания"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключить старые напоминания"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить напоминания"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Без названия"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание отсутствует"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверная дата"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вы уверены, что хотите удалить ", _interpolate(_named("count")), " пользователей?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отсутствия"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сообщить об отсутствии ", _interpolate(_named("name")), " на день"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить отсутствие"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите сообщить об отсутствии этого ученика на выбранный день?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие успешно сообщено на выбранный день."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сообщить об отсутствии. Пожалуйста, попробуйте снова."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, укажите дату и причину."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить отсутствие"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии на сессию"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите причину"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите вашу причину"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу причину здесь..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больничный"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная активность"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная локация"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в календарь"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить отсутствие"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опции фильтрации"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить об отсутствии"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите временной диапазон"])},
  "excuseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отсутствия"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствие успешно сообщено."])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования не найдены. Изучите активности в планировщике и забронируйте сейчас!"])},
  "activityDetail.calendarInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы добавить этот календарь в Google Calendar: Скопируйте предоставленную ссылку. Затем откройте Google Calendar, нажмите значок '+' рядом с 'Другие календари', выберите 'Из URL', вставьте ссылку в поле 'URL календаря' и нажмите 'Добавить календарь'."])},
  "activityDetail.linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на календарь скопирована в буфер обмена!"])},
  "activityDetail.copyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось скопировать ссылку. Пожалуйста, скопируйте вручную."])},
  "activityDetail.openIcsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть ICS ссылку"])},
  "activityDetail.importCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Импортировать календарь"])},
  "activityDetail.addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в календарь"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали оплаты недоступны в данный момент."])},
  "childAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление об отсутствии ребенка"])},
  "childAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления, когда ребенок отмечен отсутствующим."])},
  "childSessionAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление об отсутствии ребенка на сессии"])},
  "childSessionAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления, когда ребенок отмечен отсутствующим на конкретной сессии."])},
  "allDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель управления"])},
  "admin.bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования"])},
  "admin.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидающие"])},
  "admin.newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые (7д)"])},
  "admin.bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к утверждению бронирований"])},
  "admin.absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие отсутствия"])},
  "admin.childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " детей"])},
  "admin.attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты посещаемости"])},
  "admin.totalParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего участников:"])},
  "admin.attendanceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень посещаемости:"])},
  "admin.childrenExcused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дети отсутствуют:"])},
  "admin.noReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет отчетов о посещаемости."])},
  "admin.liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус бронирования в реальном времени"])},
  "admin.activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвижение активности"])},
  "admin.spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " мест доступно"])},
  "admin.sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить напоминание"])},
  "admin.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
  "status.upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие"])},
  "status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
  "status.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие"])},
  "admin.noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В настоящее время нет открытых бронирований."])},
  "admin.pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидающие запросы на отсутствие"])},
  "admin.approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к утверждению отсутствий"])},
  "admin.viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть все отсутствия"])},
  "admin.totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего сессий"])},
  "admin.participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень участия"])},
  "wholeDayExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Весь день"])},
  "enterCommentForOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите вашу причину"])},
  "excuseReverted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извинение отменено"])},
  "activityDetail.confirmedExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина подтверждена"])},
  "activityDetail.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление выполнено успешно"])},
  "activityDetail.pendingExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидающая причина"])},
  "sessionExcuseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация об уважительной причине пропуска занятия"])},
  "studentAbsenceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления об отсутствии студентов"])},
  "studentAbsenceNotificationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать уведомления по электронной почте, когда студент отмечен как отсутствующий"])},
  "preferencesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить настройки уведомлений"])},
  "failedToLoadPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить настройки уведомлений"])},
  "manageYourAccountPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление настройками вашей учетной записи"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки аккаунта"])},
  "updateYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить текущий пароль"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
  "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпочтения"])},
  "changeDisplayLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить язык интерфейса"])},
  "manageNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление способами и временем получения уведомлений"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление"])},
  "aboutApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О приложении"])},
  "checkLatestFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить последние функции и обновления"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
  "updateYourProfileInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновите информацию вашего профиля"])},
  "schoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о школе"])},
  "schoolPolicies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Школьные правила"])},
  "viewSchoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр информации о школе"])},
  "accessSchoolPoliciesAndDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к школьным правилам и информации"])},
  "schoolInfoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ниже вы можете найти информацию о правилах вашей школы"])},
  "activities": {
    "form": {
      "basicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даты"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ценообразование"])},
      "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
      "autoApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическое утверждение"])},
      "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временные слоты"])},
      "addTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить временной слот"])},
      "saveAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как черновик"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовать активность"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка активности"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать активность"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новую активность"])},
      "approvalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически утверждать регистрации на активность"])},
      "noTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временные слоты не добавлены. Нажмите \"Добавить временной слот\", чтобы начать."])}
    },
    "tabs": {
      "addEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить/Редактировать активность"])},
      "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность встречи"])}
    }
  },
  "common": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
  },
  "teacherDashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель преподавателя"])},
  "teacherDashboard.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляйте своими классами и мероприятиями"])},
  "activities.searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск мероприятий..."])},
  "activities.statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "activities.noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не найдено мероприятий, соответствующих вашим критериям."])},
  "activities.clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить фильтры"])},
  "activities.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Черновик"])},
  "activities.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "activities.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "activities.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание"])},
  "activities.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преподаватели"])},
  "activities.editTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать мероприятие"])},
  "activities.deleteTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить мероприятие"])},
  "activities.addNewTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новое мероприятие"])},
  "activities.newActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое мероприятие"])},
  "confirm.deleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить мероприятие"])},
  "confirm.deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить это мероприятие?"])},
  "confirm.cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действие нельзя отменить."])},
  "buttons.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "buttons.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "roles.teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преподаватель"])},
  "roles.assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ассистент преподавателя"])},
  "tabs.activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятия"])},
  "tabs.cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когорты"])},
  "app.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название вашей компании"])},
  "navigation.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
  "navigation.myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой календарь"])},
  "cohorts.studentsEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачисленные студенты"])},
  "cohorts.mainTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные преподаватели"])},
  "cohorts.assistantTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ассистенты преподавателей"])},
  "cohorts.viewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть детали"])},
  "enterCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите ваши учетные данные"])},
  "schoolLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип школы"])},
  "currentLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий логотип"])},
  "noLogoUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип не загружен"])},
  "selectLogoFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл логотипа"])},
  "logoSizeRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемый размер: 400x200 пикселей, макс. 2МБ"])},
  "uploadLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить логотип"])},
  "removeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить логотип"])},
  "confirmRemoveLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить логотип?"])},
  "removeLogoConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить логотип школы? Это действие нельзя отменить."])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "logoUploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип успешно загружен"])},
  "logoUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить логотип"])},
  "logoRemoveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип успешно удален"])},
  "logoRemoveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить логотип"])},
  "common.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
  "activity.participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
  "activity.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессии"])},
  "messaging.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщения"])},
  "activity.shareActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться активностью"])},
  "activity.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "common.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
  "activity.registrationDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крайний срок регистрации"])},
  "activity.nextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая сессия"])},
  "session.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
  "session.noReasonProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина не указана"])},
  "activity.loadingNextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка следующей сессии..."])},
  "activity.loadingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка деталей активности..."])},
  "attendance.toggleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось изменить статус посещаемости"])},
  "attendance.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посещаемость успешно обновлена"])},
  "attendance.updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить посещаемость"])},
  "session.rescheduleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно перенести. Данные сессии неполные"])},
  "session.rescheduleMissingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось перенести сессию. Отсутствует информация о сессии"])},
  "session.rescheduleSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия успешно перенесена"])},
  "session.noSessionToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выбрана сессия для отмены"])},
  "session.cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сессия успешно отменена"])},
  "session.cancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отменить сессию"])},
  "session.uncancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена сессии успешно отменена"])},
  "session.uncancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отменить отмену сессии"])},
  "notes.saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заметка успешно сохранена"])},
  "notes.saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить заметку"])},
  "notes.deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заметка успешно удалена"])},
  "notes.deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить заметку"])},
  "files.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
  "attendance.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посещаемость"])},
  "session.reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенести"])},
  "notes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заметка"])},
  "session.uncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить отмену"])},
  "session.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
  "session.confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите отменить эту сессию?"])},
  "session.cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отмены (необязательно)"])},
  "session.confirmUncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите отменить отмену этой сессии?"])},
  "session.cancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить сессию"])},
  "session.uncancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить отмену сессии"])},
  "fileUploader": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файлы"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите файл"])},
    "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
    "errorFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер файла должен быть меньше 5 МБ"])},
    "errorFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешены только PDF-файлы"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл успешно загружен"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить файл"])}
  },
  "fileManager": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загруженные файлы"])},
    "deleteFileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить файл"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить этот файл?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
    "actionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "downloadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось скачать файл"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл успешно удален"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить файл"])}
  },
  "attendance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посещаемость"])},
    "upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие занятия"])},
    "pastSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прошедшие занятия"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["участников"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
    "rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перенесено"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка участников..."])},
    "pdfSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF успешно сгенерирован!"])},
    "pdfError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сгенерировать PDF."])},
    "excelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel успешно сгенерирован!"])},
    "excelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сгенерировать Excel."])}
  },
  "activityParticipants": {
    "confirmedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвержденные бронирования"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировать по"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имени"])},
    "bookingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дате бронирования"])},
    "cohortTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Названию когорты"])},
    "uniqueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальному имени"])},
    "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать PDF"])},
    "slotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мест доступно"])},
    "noParticipantLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения на количество участников не установлены"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестно"])},
    "noCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когорта не назначена"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
    "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забронировано"])},
    "dateUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата неизвестна"])},
    "dayUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День неизвестен"])},
    "startUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время начала неизвестно"])},
    "endUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время окончания неизвестно"])},
    "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место неизвестно"])},
    "availableSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступное место"])},
    "childDataMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные о ребенке отсутствуют"])},
    "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этой деятельности не найдено подтвержденных участников."])},
    "waitlistedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования в листе ожидания"])},
    "pendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидающие бронирования"])},
    "waitlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В листе ожидания"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрить"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])},
    "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить бронирование"])},
    "bookingApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование успешно одобрено!"])},
    "bookingApprovalFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось одобрить бронирование. Пожалуйста, попробуйте снова."])},
    "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная деятельность"])},
    "bookingListFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список бронирований для"])},
    "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название деятельности"])},
    "childName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя ребенка"])},
    "bookingDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время бронирования"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "fontLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить шрифт для PDF. Пожалуйста, попробуйте снова."])},
    "pdfGenerationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сгенерировать PDF. Пожалуйста, попробуйте снова."])}
  },
  "navbar": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой календарь"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип компании"])}
  },
  "perMonthPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ежемесячная подписка, одинаковая цена независимо от выбранных сессий)"])},
  "perYearPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(годовая подписка, одинаковая цена независимо от выбранных сессий)"])},
  "quarterlyPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(квартальная подписка, одинаковая цена независимо от выбранных сессий)"])},
  "biAnnualPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(полугодовая подписка, одинаковая цена независимо от выбранных сессий)"])},
  "perSessionPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(цена за отдельную сессию)"])},
  "totalPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(единоразовый общий платеж)"])},
  "selectedSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбранные сессии"])},
  "selectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать роль"])},
  "multipleRolesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас несколько ролей. Пожалуйста, выберите, какую роль вы хотите использовать."])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администратор"])},
  "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Учитель"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Родитель"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
  "activityParticipants.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["от"])},
  "activityParticipants.approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одобрено"])},
  "adminDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление пользователями, мероприятиями и настройками системы"])},
  "teacherDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавайте мероприятия и управляйте своими учениками"])},
  "parentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записывайте детей на мероприятия и просматривайте их расписание"])},
  "childDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просматривайте свои мероприятия и расписание"])},
  "switchRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить роль"])},
  "switchToRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключиться на другую роль"])},
  "selectRoleToSwitchTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите, какую роль вы хотите использовать:"])},
  "monthlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ежемесячная цена покрывает все ", _interpolate(_named("count")), " выбранных сессий"])},
  "yearlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Годовая цена покрывает все ", _interpolate(_named("count")), " выбранных сессий"])},
  "quarterlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Квартальная цена покрывает все ", _interpolate(_named("count")), " выбранных сессий"])},
  "biAnnualPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Полугодовая цена покрывает все ", _interpolate(_named("count")), " выбранных сессий"])},
  "admin": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Панель управления"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидающие"])},
    "newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые бронирования"])},
    "bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение бронирования"])},
    "pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы на отсутствие"])},
    "approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр подтверждения"])},
    "absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствия"])},
    "childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Отсутствующих детей"])},
    "viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все отсутствия"])},
    "attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты о посещаемости"])},
    "totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего занятий"])},
    "participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатель участия"])},
    "liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус бронирования"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОНЛАЙН"])},
    "activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продвижение активностей"])},
    "spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Доступных мест"])},
    "sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить напоминание"])},
    "noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных бронирований"])},
    "allAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все отсутствия"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней"])},
    "noAbsencesToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня нет отсутствий"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вместимость"])},
    "enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачислено"])},
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
    "noActivitiesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступных активностей"])},
    "currentParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущие участники"])},
    "waitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лист ожидания"])},
    "availableSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные места"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активности"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])},
    "totalEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего зачислено"])},
    "totalWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего в ожидании"])},
    "totalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего доступно"])},
    "occupancyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполняемость"])},
    "errorFetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при получении данных"])},
    "failedToSendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить напоминание"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя неделя"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний месяц"])},
    "lastQuarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний квартал"])},
    "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний год"])},
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все время"])},
    "customRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произвольный период"])},
    "selectDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])}
  },
  "admin.selectBothDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите дату начала и дату окончания."])},
  "teacherAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления об отсутствии учителя"])},
  "teacherAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте уведомления, когда учитель сообщает об отсутствии"])},
  "activityBookingReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания о бронировании мероприятий"])},
  "activityBookingReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получайте напоминания о мероприятиях, на которые ваш ребенок имеет право присоединиться, но еще не зарегистрирован"])}
}