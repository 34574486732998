export default {
  "Locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietas"])},
  "systemNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svarīgi: Pašlaik mēs veicam sistēmas uzlabošanas darbus, lai uzlabotu portāla darbību. Jūs varat pieredzēt īslaicīgus pārtraukumus, kamēr mēs uzlabojam mūsu pakalpojumu. Pateicamies par jūsu pacietību."])},
  "activityParticipants.slotTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika slots"])},
  "activityParticipants.bookingRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija noraidīta"])},
  "Teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāji"])},
  "Cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas"])},
  "noFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav piemēroti filtri"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notīrīt visu"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasīt visu"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietas"])},
  "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāji"])},
  "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt vietas..."])},
  "searchTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt skolotājus..."])},
  "searchCohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt kohortas..."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neviens"])},
  "selectedFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasītie filtri"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atlasīti"])},
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolas administrēšanas vienkāršošana"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laipni lūgti SchoolPeak portālā!"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsauksmes"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājvārds vai Epasts"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieslēgties"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepareizs lietotājvārds vai parole"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidot administratoru"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājvārds"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epasts"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidot"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumi"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumu pārvaldība"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatījumi"])},
  "reminderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumu pārvaldība"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumi"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties valodu"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainīt paroli"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējā parole"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šī parole tiks izmantota lietotāja pieteikšanās. Pārliecinieties, ka tā ir droša. Administratori var iestatīt un mainīt paroles, bet lietotāji var tās mainīt pēc pieteikšanās. Administratori nevar skatīt lietotāju paroles."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunā parole"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstipriniet jauno paroli"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pieejamu slotu"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties laika slotus"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atkārtota rezervācija"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai vēlaties rezervēt šo laika slotu katru nedēļu?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jā"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nē"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieejams"])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāja loma"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot lietotāju"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt melnrakstu"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt izmaiņas"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profils veiksmīgi atjaunināts"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizmirsāt paroli?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatiet savu paroli"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu e-pasta adresi"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosūtīt atiestatīšanas saiti"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasts ir obligāts"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīšanas saite nosūtīta! Lūdzu, pārbaudiet savu e-pastu."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās nosūtīt atiestatīšanas saiti. Lūdzu, mēģiniet vēlreiz."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-pasts un parole ir obligāti"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi lauki ir obligāti, lai izveidotu administratoru"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma detaļas"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicēt lietotāju"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodrošiniet savu kontu"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties jaunu paroli, lai atgūtu piekļuvi"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatiet savu paroli"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstipriniet paroli"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole ir obligāta"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolei jābūt vismaz 8 rakstzīmēm"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolei jāietver vismaz viens cipars"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole ir pārāk vāja"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu parole ir veiksmīgi atiestatīta"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atiestatīt paroli"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iesniegt"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz pieteikšanos"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumu iestatījumi"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atceltu sesiju paziņojumi"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieslēgt paziņojumus par atceltām sesijām"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārceltu sesiju paziņojumi"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieslēgt paziņojumus par pārceltām sesijām"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versija"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatījumi veiksmīgi saglabāti"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atjaunināt profilu"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājuma detaļas"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt maksājuma detaļas"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt paroli"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunās paroles nesakrīt."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole veiksmīgi atjaunināta."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atjaunināt paroli."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija pievienota"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jauna sesija ir veiksmīgi pievienota."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija atcelta"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija ir atcelta un turpināsies kā plānots."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas piezīmju paziņojumi"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt paziņojumus, kad sesijai tiek pievienota vai atjaunināta piezīme."])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šī sesija jau ir rezervēta. Lūdzu, izvēlieties citu."])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija veiksmīgi atcelta."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jauna ziņa paziņojums"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt e-pasta paziņojumu, kad saņemat jaunu ziņu."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumu paziņojumi"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt paziņojumus par jauniem atgādinājumiem, kas piešķirti jums vai jūsu bērna grupai."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunu aktivitāšu paziņojumi"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt paziņojumus par jaunām aktivitātēm, kas pieejamas jūsu bērnam."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labi"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["izvēlēties"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angļu"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vācu"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latviešu"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krievu"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valoda"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas aktivitātes"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāju pārvaldība"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietu pārvaldība"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupas pārvaldība"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendāra skats"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kas jauns"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildnodarbības"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāji"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigācija"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt aktivitātes"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties vietu"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties skolotāju"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties grupu"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piemērot"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz detaļām"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēneša skats"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedēļas skats"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienas skats"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprinājumi"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brīvdienu pārvaldība"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šobrīd nav gaidāmo rezervāciju."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt rezervāciju"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties rezervēt ", _interpolate(_named("activityName")), " priekš ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt rezervāciju"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai vēlaties rezervēt"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priekš"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties visus bērnus"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jūsu informācijai, aktivitātes cena ir ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cena par sesiju ir ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Maksājums tiks pievienots jūsu mēneša rēķinam."])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja noklikšķināt 'Es apstiprinu', sesijas izmaksas tiks pievienotas jūsu mēneša rēķinam."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mēnesī"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gadā"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija apstiprināta"])},
  "confirmSlotBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt slota rezervāciju"])},
  "slotDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lūdzu, apstipriniet rezervāciju šim slotam: Datums: ", _interpolate(_named("date")), ", Sākuma laiks: ", _interpolate(_named("startTime")), ", Beigu laiks: ", _interpolate(_named("endTime"))])},
  "slotBookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slota rezervācija apstiprināta!"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis slots jau ir rezervēts. Lūdzu, izvēlieties citu pieejamo laiku."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aktivitāte '", _interpolate(_named("activity")), "' veiksmīgi rezervēta."])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ceturksnī"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusgadā"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kopā"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es apstiprinu"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mani profila dati"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt profilu"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējās aktivitātes"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās aktivitātes"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nākamais"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprinājums par saistību"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja noklikšķināt 'Es apstiprinu', aktivitātes izmaksas tiks pievienotas jūsu mēneša rēķinam nākamos 3 mēnešus."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja noklikšķināt 'Es apstiprinu', aktivitātes izmaksas tiks pievienotas jūsu gada rēķinam."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja noklikšķināt 'Es apstiprinu', aktivitātes izmaksas tiks sadalītas ceturkšņa maksājumos."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja noklikšķināt 'Es apstiprinu', aktivitātes izmaksas tiks sadalītas pusgada maksājumos."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja noklikšķināt 'Es apstiprinu', pilna aktivitātes summa tiks iekasēta uzreiz."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt pēc bērna"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaut kas nogāja greizi. Lūdzu, mēģiniet vēlāk."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstrāde..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāte veiksmīgi rezervēta, gaida apstiprinājumu."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties atcelt rezervāciju ", _interpolate(_named("activityName")), " priekš ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt atcelšanu"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt darbību"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties turpināt šo darbību?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija atcelta."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vārds"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika sloti"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalībnieki"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupas"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbības"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rindas lapā"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lomu pārvaldība"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laipni lūdzam"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šeit jūs redzat aktivitātes"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav gaidāmo sesiju"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu nākamie notikumi"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu atvērtās rezervācijas"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bērns:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt rezervāciju"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt atcelšanu"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendārs"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plānotājs"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties datumu"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties iemeslu"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzrakstiet savu iemeslu"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu iemeslu šeit..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slimības atvaļinājums"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cits"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šodien"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties skatu"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diena"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedēļa"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mēnesis"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gads"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas datums"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt aktivitātes"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas aktivitātes"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iepriekšējās aktivitātes"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiekošās aktivitātes"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākotnes aktivitātes"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties filtru"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt pēc vārda"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iela"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilsēta"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasta indekss"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tālrunis"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par aktivitāti"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faili"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datums"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vieta"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāji"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās sesijas"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iepriekšējās sesijas"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiek aktivitātes detaļu ielāde..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelts"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārcelts"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieplānots"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noklusējums"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākums"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plānotājs"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrs"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendārs"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visi bērni"])},
  "selectChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties bērnu"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Šobrīd nav aktivitāšu priekš ", _interpolate(_named("childName")), ". Lūdzu, pārbaudiet vēlāk."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgādinājumi"])},
  "navbar.messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņas"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mani atgādinājumi"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izslēgt vecos atgādinājumus"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosaukums"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apraksts"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termiņš"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās ielādēt atgādinājumus"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bez nosaukuma"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav apraksta"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederīgs datums"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai tiešām vēlaties dzēst ", _interpolate(_named("count")), " lietotāju(s)?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iemesls prombūtnei"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par prombūtni"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtnē"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ziņot par ", _interpolate(_named("name")), " prombūtni dienā"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt prombūtni"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties ziņot par šī skolēna prombūtni izvēlētajā dienā?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtne veiksmīgi ziņota izvēlētajā dienā."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās ziņot par prombūtni. Lūdzu, mēģiniet vēlreiz."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, norādiet datumu un iemeslu."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par prombūtni"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt prombūtni"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par prombūtni sesijā"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet iemeslu"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzrakstiet savu iemeslu"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu iemeslu šeit..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slimības atvaļinājums"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cits"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezināma aktivitāte"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezināma vieta"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot kalendāram"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par prombūtni"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt prombūtni"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēšanas opcijas"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņot par prombūtni"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties laika diapazonu"])},
  "excuseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtnes datums"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtne veiksmīgi ziņota."])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas nav atrastas. Izpētiet aktivitātes plānotājā un rezervējiet tagad!"])},
  "activityDetail.calendarInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai pievienotu šo kalendāru Google Calendar: Nokopējiet saiti. Pēc tam atveriet Google Calendar, noklikšķiniet uz '+' ikonas blakus 'Citi kalendāri', atlasiet 'No URL', ielīmējiet saiti laukā 'Kalendāra URL' un noklikšķiniet 'Pievienot kalendāru'."])},
  "activityDetail.linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalendāra saite nokopēta starpliktuvē!"])},
  "activityDetail.copyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās nokopēt saiti. Lūdzu, nokopējiet to manuāli."])},
  "activityDetail.openIcsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atvērt ICS saiti"])},
  "activityDetail.importCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importēt kalendāru"])},
  "activityDetail.addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot kalendāram"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma detaļas šobrīd nav pieejamas."])},
  "childAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bērna prombūtnes paziņojums"])},
  "childAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt paziņojumus, kad bērns ir atzīmēts kā prombūtnē."])},
  "childSessionAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bērna sesijas prombūtnes paziņojums"])},
  "childSessionAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemt paziņojumus, kad bērns ir atzīmēts kā prombūtnē konkrētā sesijā."])},
  "allDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informācijas panelis"])},
  "admin.bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas"])},
  "admin.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaida"])},
  "admin.newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunas (7d)"])},
  "admin.bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz rezervāciju apstiprināšanu"])},
  "admin.absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējās prombūtnes"])},
  "admin.childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " bērni"])},
  "admin.attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmeklējuma atskaites"])},
  "admin.totalParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopējais dalībnieku skaits:"])},
  "admin.attendanceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmeklējuma līmenis:"])},
  "admin.childrenExcused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bērni prombūtnē:"])},
  "admin.noReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav apmeklējuma atskaišu."])},
  "admin.liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervāciju statuss reāllaikā"])},
  "admin.activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāšu popularizēšana"])},
  "admin.spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " vietas pieejamas"])},
  "admin.sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosūtīt atgādinājumu"])},
  "admin.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "status.upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmie"])},
  "status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabeigti"])},
  "status.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiekošie"])},
  "admin.noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šobrīd nav atvērto rezervāciju."])},
  "admin.pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtņu pieprasījumi"])},
  "admin.approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz prombūtņu apstiprināšanu"])},
  "admin.viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt visas prombūtnes"])},
  "admin.totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopējais sesiju skaits"])},
  "admin.participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalības līmenis"])},
  "wholeDayExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa diena"])},
  "enterCommentForOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet savu iemeslu"])},
  "excuseReverted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attaisnojums atsaukts"])},
  "activityDetail.confirmedExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attaisnojums apstiprināts"])},
  "activityDetail.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināšana veiksmīga"])},
  "activityDetail.pendingExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neapstiprināts attaisnojums"])},
  "sessionExcuseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas attaisnojuma informācija"])},
  "studentAbsenceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über Schülerabwesenheit"])},
  "studentAbsenceNotificationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Benachrichtigungen erhalten, wenn ein Schüler als abwesend markiert wird"])},
  "preferencesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen konnten nicht gespeichert werden"])},
  "failedToLoadPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen konnten nicht geladen werden"])},
  "manageYourAccountPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārvaldiet sava konta iestatījumus"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta iestatījumi"])},
  "updateYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunināt pašreizējo paroli"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainīt"])},
  "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
  "changeDisplayLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainīt interfeisa valodu"])},
  "manageNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārvaldiet, kā un kad saņemat paziņojumus"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārvaldīt"])},
  "aboutApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par lietotni"])},
  "checkLatestFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārbaudiet jaunākās funkcijas un atjauninājumus"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatījumi"])},
  "updateYourProfileInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjauniniet sava profila informāciju"])},
  "schoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolas informācija"])},
  "schoolPolicies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolas noteikumi"])},
  "viewSchoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt skolas informāciju"])},
  "accessSchoolPoliciesAndDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piekļūt skolas noteikumiem un informācijai"])},
  "schoolInfoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemāk varat atrast informāciju par jūsu skolas noteikumiem"])},
  "activities": {
    "form": {
      "basicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamatinformācija"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apraksts"])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumi"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cenu veidošana"])},
      "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupas"])},
      "autoApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automātisks apstiprinājums"])},
      "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laika slots"])},
      "addTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot laika slotu"])},
      "saveAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt kā melnrakstu"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicēt aktivitāti"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitātes apstrāde"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt aktivitāti"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot jaunu aktivitāti"])},
      "approvalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automātiski apstiprināt aktivitātes reģistrāciju"])},
      "noTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pievienotu laika slotu. Noklikšķiniet uz \"Pievienot laika slotu\", lai sāktu."])}
    },
    "tabs": {
      "addEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot/Rediģēt aktivitāti"])},
      "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieraksta aktivitāte"])}
    }
  },
  "common": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atpakaļ"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noņemt"])}
  },
  "teacherDashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāja panelis"])},
  "teacherDashboard.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārvaldiet savas klases un aktivitātes"])},
  "activities.searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt aktivitātes..."])},
  "activities.statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuss"])},
  "activities.noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav atrasta neviena aktivitāte, kas atbilstu jūsu kritērijiem."])},
  "activities.clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notīrīt filtrus"])},
  "activities.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melnraksts"])},
  "activities.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākuma datums"])},
  "activities.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigu datums"])},
  "activities.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafiks"])},
  "activities.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāji"])},
  "activities.editTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt aktivitāti"])},
  "activities.deleteTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst aktivitāti"])},
  "activities.addNewTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot jaunu aktivitāti"])},
  "activities.newActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jauna aktivitāte"])},
  "confirm.deleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst aktivitāti"])},
  "confirm.deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties dzēst šo aktivitāti?"])},
  "confirm.cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šo darbību nevar atsaukt."])},
  "buttons.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "buttons.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst"])},
  "roles.teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotājs"])},
  "roles.assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāja asistents"])},
  "tabs.activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitātes"])},
  "tabs.cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas"])},
  "app.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu uzņēmuma nosaukums"])},
  "navigation.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskats"])},
  "navigation.myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mans kalendārs"])},
  "cohorts.studentsEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reģistrētie studenti"])},
  "cohorts.mainTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galvenie skolotāji"])},
  "cohorts.assistantTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāju asistenti"])},
  "cohorts.viewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt detaļas"])},
  "enterCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, ievadiet savus pieteikšanās datus"])},
  "schoolLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolas logo"])},
  "currentLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējais logo"])},
  "noLogoUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav augšupielādēts logo"])},
  "selectLogoFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties logo failu"])},
  "logoSizeRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieteicamais izmērs: 400x200 pikseļi, maks. 2MB"])},
  "uploadLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādēt logo"])},
  "removeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noņemt logo"])},
  "confirmRemoveLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noņemt logo?"])},
  "removeLogoConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties noņemt skolas logo? Šo darbību nevar atsaukt."])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noņemt"])},
  "logoUploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo veiksmīgi augšupielādēts"])},
  "logoUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās augšupielādēt logo"])},
  "logoRemoveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo veiksmīgi noņemts"])},
  "logoRemoveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās noņemt logo"])},
  "common.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
  "activity.participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalībnieki"])},
  "activity.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas"])},
  "messaging.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņojumapmaiņa"])},
  "activity.shareActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalīties ar aktivitāti"])},
  "activity.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datums"])},
  "common.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["līdz"])},
  "activity.registrationDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reģistrācijas termiņš"])},
  "activity.nextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākamā sesija"])},
  "session.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelts"])},
  "session.noReasonProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iemesls nav norādīts"])},
  "activity.loadingNextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ielādē nākamo sesiju..."])},
  "activity.loadingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ielādē aktivitātes informāciju..."])},
  "attendance.toggleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās mainīt apmeklējuma statusu"])},
  "attendance.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmeklējums veiksmīgi atjaunināts"])},
  "attendance.updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās saglabāt apmeklējumu"])},
  "session.rescheduleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevar pārplānot. Sesijas dati ir nepilnīgi"])},
  "session.rescheduleMissingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās pārplānot sesiju. Trūkst sesijas informācijas"])},
  "session.rescheduleSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija veiksmīgi pārplānota"])},
  "session.noSessionToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav atlasīta sesija atcelšanai"])},
  "session.cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija veiksmīgi atcelta"])},
  "session.cancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atcelt sesiju"])},
  "session.uncancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas atcelšana veiksmīgi atcelta"])},
  "session.uncancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās atcelt sesijas atcelšanu"])},
  "notes.saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piezīme veiksmīgi saglabāta"])},
  "notes.saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās saglabāt piezīmi"])},
  "notes.deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piezīme veiksmīgi izdzēsta"])},
  "notes.deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās izdzēst piezīmi"])},
  "files.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādēt"])},
  "attendance.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmeklējums"])},
  "session.reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārplānot"])},
  "notes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piezīme"])},
  "session.uncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt atcelšanu"])},
  "session.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "session.confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties atcelt šo sesiju?"])},
  "session.cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelšanas iemesls (neobligāti)"])},
  "session.confirmUncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties atcelt šīs sesijas atcelšanu?"])},
  "session.cancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt sesiju"])},
  "session.uncancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt sesijas atcelšanu"])},
  "fileUploader": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādēt failus"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlēties failu"])},
    "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādēt"])},
    "errorFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faila izmēram jābūt mazākam par 5 MB"])},
    "errorFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atļauti tikai PDF faili"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails veiksmīgi augšupielādēts"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās augšupielādēt failu"])}
  },
  "fileManager": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādētie faili"])},
    "deleteFileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst failu"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties dzēst šo failu?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jā"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nē"])},
    "actionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbības"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādēt"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst"])},
    "downloadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās lejupielādēt failu"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fails veiksmīgi dzēsts"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās dzēst failu"])}
  },
  "attendance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmeklējums"])},
    "upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās nodarbības"])},
    "pastSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagājušās nodarbības"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dalībnieki"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelts"])},
    "rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārcelts"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piezīme"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ielādē dalībniekus..."])},
    "pdfSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF veiksmīgi izveidots!"])},
    "pdfError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās izveidot PDF."])},
    "excelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel veiksmīgi izveidots!"])},
    "excelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās izveidot Excel."])}
  },
  "activityParticipants": {
    "confirmedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprinātie rezervējumi"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kārtot pēc"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vārda"])},
    "bookingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas datuma"])},
    "cohortTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortas nosaukuma"])},
    "uniqueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unikāla vārda"])},
    "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādēt PDF"])},
    "slotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vietas pieejamas"])},
    "noParticipantLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav iestatīti dalībnieku ierobežojumi"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezināms"])},
    "noCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav piešķirta kohorta"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
    "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervēts"])},
    "dateUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datums nezināms"])},
    "dayUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diena nezināma"])},
    "startUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākums nezināms"])},
    "endUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beigas nezināmas"])},
    "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrašanās vieta nezināma"])},
    "availableSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieejama vieta"])},
    "childDataMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trūkst bērna datu"])},
    "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šai aktivitātei nav atrasti apstiprināti dalībnieki."])},
    "waitlistedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidīšanas saraksta rezervācijas"])},
    "pendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neapstiprinātās rezervācijas"])},
    "waitlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidīšanas sarakstā"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaida"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noraidīt"])},
    "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot rezervāciju"])},
    "bookingApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācija veiksmīgi apstiprināta!"])},
    "bookingApprovalFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās apstiprināt rezervāciju. Lūdzu, mēģiniet vēlreiz."])},
    "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezināma aktivitāte"])},
    "bookingListFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervāciju saraksts"])},
    "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitātes nosaukums"])},
    "childName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bērna vārds"])},
    "bookingDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas datums un laiks"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuss"])},
    "fontLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās ielādēt fontu PDF dokumentam. Lūdzu, mēģiniet vēlreiz."])},
    "pdfGenerationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās izveidot PDF. Lūdzu, mēģiniet vēlreiz."])}
  },
  "navbar": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlne"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sākums"])},
    "myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mans kalendārs"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsauksmes"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paziņojumi"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
    "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzņēmuma logotips"])}
  },
  "perMonthPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ikmēneša abonements, vienāda cena neatkarīgi no izvēlētajām sesijām)"])},
  "multipleSlotsBookingConfirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veiksmīgi rezervētas ", _interpolate(_named("count")), " sesijas"])},
  "priceSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cenu kopsavilkums"])},
  "monthlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mēneša cena ietver visas ", _interpolate(_named("count")), " izvēlētās sesijas"])},
  "pricePerSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena par sesiju"])},
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopējā cena"])},
  "selectedSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlētās sesijas"])},
  "fullyBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilnībā rezervēts"])},
  "perYearPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(gada abonements, vienāda cena neatkarīgi no izvēlētajām sesijām)"])},
  "quarterlyPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ceturkšņa abonements, vienāda cena neatkarīgi no izvēlētajām sesijām)"])},
  "biAnnualPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(pusgada abonements, vienāda cena neatkarīgi no izvēlētajām sesijām)"])},
  "perSessionPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(cena par atsevišķu sesiju)"])},
  "totalPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(vienreizējs kopējais maksājums)"])},
  "selectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlēties lomu"])},
  "multipleRolesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums ir vairākas lomas. Lūdzu, izvēlieties, kuru lomu vēlaties izmantot."])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrators"])},
  "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotājs"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecāks"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iziet"])},
  "activityParticipants.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autors"])},
  "activityParticipants.approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināts"])},
  "adminDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārvaldiet lietotājus, aktivitātes un sistēmas iestatījumus"])},
  "teacherDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veidojiet aktivitātes un pārvaldiet savus skolēnus"])},
  "parentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piesakieties aktivitātēm saviem bērniem un skatiet viņu grafiku"])},
  "childDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatiet savas aktivitātes un grafiku"])},
  "switchRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainīt lomu"])},
  "switchToRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārslēgties uz citu lomu"])},
  "selectRoleToSwitchTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties, kuru lomu vēlaties izmantot:"])},
  "yearlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gada cena ietver visas ", _interpolate(_named("count")), " izvēlētās sesijas"])},
  "quarterlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ceturkšņa cena ietver visas ", _interpolate(_named("count")), " izvēlētās sesijas"])},
  "biAnnualPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pusgada cena ietver visas ", _interpolate(_named("count")), " izvēlētās sesijas"])},
  "admin": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panelis"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervācijas"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmās"])},
    "newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaunas rezervācijas"])},
    "bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervāciju apstiprināšana"])},
    "pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidāmie prombūtnes pieprasījumi"])},
    "approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināšanas centrs"])},
    "absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prombūtnes"])},
    "childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Bērni prombūtnē"])},
    "viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt visas prombūtnes"])},
    "attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmeklējumu pārskati"])},
    "totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopējās sesijas"])},
    "participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Līdzdalības līmenis"])},
    "liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiešraides rezervēšanas statuss"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIEŠRAIDE"])},
    "activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāšu veicināšana"])},
    "spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Pieejamās vietas"])},
    "sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nosūtīt atgādinājumu"])},
    "noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pieejamu atvērtu rezervāciju"])},
    "allAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visas prombūtnes"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dienas"])},
    "noAbsencesToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šodien nav prombūtņu"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāte"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ietilpība"])},
    "enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reģistrēti"])},
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidīšana"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieejams"])},
    "noActivitiesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pieejamu aktivitāšu"])},
    "currentParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pašreizējie dalībnieki"])},
    "waitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gaidīšanas saraksts"])},
    "availableSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieejamās vietas"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitātes"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalībnieki"])},
    "totalEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopā reģistrēti"])},
    "totalWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopā gaida"])},
    "totalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopā pieejams"])},
    "occupancyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizņemtības līmenis"])},
    "errorFetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda, iegūstot datus"])},
    "failedToSendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās nosūtīt atgādinājumu"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pēdējā nedēļa"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pēdējais mēnesis"])},
    "lastQuarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pēdējais ceturksnis"])},
    "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pēdējais gads"])},
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viss laiks"])},
    "customRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pielāgots periods"])},
    "selectDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties datumu diapazonu"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pielietot"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])}
  },
  "admin.selectBothDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lūdzu, izvēlieties sākuma un beigu datumu."])},
  "teacherAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skolotāju prombūtnes paziņojumi"])},
  "teacherAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet paziņojumus, kad skolotājs ziņo par prombūtni"])},
  "activityBookingReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitāšu rezervēšanas atgādinājumi"])},
  "activityBookingReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saņemiet atgādinājumus par aktivitātēm, kurām jūsu bērns ir tiesīgs pievienoties, bet vēl nav reģistrēts"])}
}