export default {
  "systemNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important: We are currently performing system maintenance to improve performance. You may experience brief interruptions while we enhance our service. Thank you for your patience."])},
  "activityParticipants.slotTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot Time"])},
  "activityParticipants.bookingRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Rejected"])},
  "activityParticipants.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
  "activityParticipants.approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "Locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
  "Teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
  "Cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohorts"])},
  "noFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No filters applied"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select All"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
  "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
  "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search locations..."])},
  "searchTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search teachers..."])},
  "searchCohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search cohorts..."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "selectedFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Filters"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected"])},
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifying School Administration"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the SchoolPeak portal!"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name or Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid username or password"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Admin"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders Management"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "reminderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder Management"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password will be used by the user to log in. Ensure it is secure. Admins can set and change passwords, but users can change them after logging in. Admins cannot view user passwords."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No slots available"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select time slots"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring Booking"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book this time slot for every week?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Role"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add User"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Draft"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated successfully"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Your Password"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reset Link"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is required"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset link sent! Please check your email."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send reset link. Please try again."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email and password are required"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All fields are required to create an admin"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Details"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish User"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure Your Account"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a new password to regain access"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Your Password"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is required"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must include at least one number"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too weak"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been reset successfully"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reset the password"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Login"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled Session Notifications"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable notifications for canceled sessions"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled Session Notifications"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable notifications for rescheduled sessions"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences have been successfully saved"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update profile"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Payment Details"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Payment Details"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New passwords do not match."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password updated successfully."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update password."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Added"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new session has been successfully added."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Uncancelled"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The session has been uncancelled and will proceed as planned."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Notes Notification"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications when a note is added or updated for a session."])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This session has already been booked. Please choose a different one."])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking has been successfully canceled."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message Notification"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive an email notification when you receive a new message."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder Notifications"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications for new reminders assigned to you or your child's cohort."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Activity Notifications"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications for new activities available for your child."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvian"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Management"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Management"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohort Management"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar View"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's New"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extracurricular Activities"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Activities"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Location"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Teacher"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Cohort"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Details"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month View"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week View"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day View"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvals"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday Management"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pending bookings at the moment."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Booking"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to book ", _interpolate(_named("activityName")), " for ", _interpolate(_named("childName")), "?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Booking"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to book"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])},
  "selectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Role"])},
  "multipleRolesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have multiple roles. Please select which role you would like to use."])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "adminDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage users, activities, and system settings"])},
  "teacherDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create activities and manage your students"])},
  "parentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book activities for your children and view their schedule"])},
  "childDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your activities and schedule"])},
  "switchRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch Role"])},
  "switchToRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch to a Different Role"])},
  "selectRoleToSwitchTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select which role you would like to use:"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all children"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For your information, the price for the activity is ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The price is ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". The payment is added to your monthly invoice."])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the session will be added to your monthly invoice."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per month"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per year"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking confirmed"])},
  "confirmSlotBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Slot Booking"])},
  "slotDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please confirm the booking for the following slot: Date: ", _interpolate(_named("date")), ", Start Time: ", _interpolate(_named("startTime")), ", End Time: ", _interpolate(_named("endTime"))])},
  "slotBookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot booking confirmed!"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This slot has already been booked. Please choose another available time."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The activity '", _interpolate(_named("activity")), "' has been successfully booked."])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quarterly"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bi-annual"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Commit"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile Data"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Activities"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Activities"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commitment Confirmation"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be added to your monthly invoice for the next 3 months."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be added to your yearly invoice."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be divided into quarterly payments."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the cost of the activity will be divided into bi-annual payments."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you click, 'I Commit,' the full cost of the activity will be charged immediately."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Child"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity booked successfully, waiting for approval."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to cancel the booking for ", _interpolate(_named("activityName")), " for ", _interpolate(_named("childName")), "?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Cancellation"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Action"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to proceed with this action?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking has been canceled."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Slots"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohorts"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Management"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you see the activities of"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No upcoming sessions available"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your next events"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your open bookings"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Booking"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Cancellation"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohort"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planner"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Reason"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reason"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your reason here..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick Leave"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select View"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Booked"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Activities"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Activities"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Activities"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future Activities"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Filter"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip Code"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Sessions"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Sessions"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading activity details..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planner"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Hub"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Children"])},
  "selectChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Child"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Currently, there are no activities available for ", _interpolate(_named("childName")), ". Please check back later for updates."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
  "navbar.messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Reminders"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclude old reminders"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch reminders"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untitled"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No description provided"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Date"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete ", _interpolate(_named("count")), " user(s)?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for absence"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absent"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Report ", _interpolate(_named("name")), "'s absence for a day"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Absence"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to report this student's absence for the selected day?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence successfully reported for the selected day."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to report the absence. Please try again."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide both a date and a valid reason."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert Absence"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence for Session"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Reason"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your reason"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your reason here..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sick Leave"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Activity"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Location"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Calendar"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert Absence"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter Options"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Absence"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Time Range"])},
  "excuseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuse Date"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence reported successfully."])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bookings found. Explore activities under planner and book now!"])},
  "activityDetail.calendarInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To add this calendar to Google Calendar: Copy the link provided. Then, open Google Calendar, click the '+' icon next to 'Other calendars', select 'From URL', paste the link into the 'URL of calendar' field, and click 'Add calendar'."])},
  "activityDetail.linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar link copied to clipboard!"])},
  "activityDetail.copyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to copy link. Please copy it manually."])},
  "activityDetail.openIcsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open ICS Link"])},
  "activityDetail.importCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Calendar"])},
  "activityDetail.addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to Calendar"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details are not available at the moment."])},
  "childAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Absence Notification"])},
  "childAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications when a child is marked absent."])},
  "childSessionAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Session Absence Notification"])},
  "childSessionAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications when a child is marked absent for a specific session."])},
  "allDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "admin.bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
  "admin.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "admin.newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New (7d)"])},
  "admin.bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Booking Approval"])},
  "admin.absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Absences"])},
  "admin.childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " children"])},
  "admin.attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Reports"])},
  "admin.totalParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Participants:"])},
  "admin.attendanceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Rate:"])},
  "admin.childrenExcused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children Excused:"])},
  "admin.noReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No attendance reports available."])},
  "admin.liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Booking Status"])},
  "admin.activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Promotion"])},
  "admin.spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " spots available"])},
  "admin.sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reminder"])},
  "admin.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "status.upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
  "status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "status.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "perMonthPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(monthly subscription, same price regardless of selected sessions)"])},
  "multipleSlotsBookingConfirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " slots successfully booked"])},
  "priceSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price Summary"])},
  "perYearPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(yearly subscription, same price regardless of selected sessions)"])},
  "quarterlyPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(quarterly subscription, same price regardless of selected sessions)"])},
  "biAnnualPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(bi-annual subscription, same price regardless of selected sessions)"])},
  "perSessionPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(price per individual session)"])},
  "totalPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(one-time total payment)"])},
  "selectedSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected sessions"])},
  "monthlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Monthly price covers all ", _interpolate(_named("count")), " selected sessions"])},
  "yearlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yearly price covers all ", _interpolate(_named("count")), " selected sessions"])},
  "quarterlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quarterly price covers all ", _interpolate(_named("count")), " selected sessions"])},
  "biAnnualPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bi-annual price covers all ", _interpolate(_named("count")), " selected sessions"])},
  "admin.noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no open bookings."])},
  "admin.pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence Requests"])},
  "admin.approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Absence Approval"])},
  "admin.viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all absences"])},
  "admin.totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sessions"])},
  "admin.participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation Rate"])},
  "admin.pendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Bookings"])},
  "wholeDayExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole Day"])},
  "enterCommentForOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your reason"])},
  "excuseReverted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuse withdrawn successfully"])},
  "activityDetail.confirmedExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excuse confirmed"])},
  "activityDetail.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update successful"])},
  "activityDetail.pendingExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending excuse"])},
  "sessionExcuseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session excuse information"])},
  "studentAbsenceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student Absence Notifications"])},
  "studentAbsenceNotificationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive email notifications when a student is marked absent"])},
  "preferencesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save notification preferences"])},
  "failedToLoadPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load notification preferences"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "manageYourAccountPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your account preferences and settings"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
  "updateYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your current password"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])},
  "changeDisplayLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change the display language"])},
  "manageNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage how and when you receive notifications"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])},
  "aboutApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About App"])},
  "checkLatestFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the latest features and updates"])},
  "updateYourProfileInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update your profile information"])},
  "schoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Information"])},
  "schoolPolicies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Policies"])},
  "viewSchoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View School Information"])},
  "accessSchoolPoliciesAndDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access school policies and information"])},
  "schoolInfoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below you can find information about your school policies"])},
  "activities": {
    "form": {
      "basicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
      "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohorts"])},
      "autoApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic Approval"])},
      "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Slots"])},
      "addTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Time Slot"])},
      "saveAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as Draft"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish Activity"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Activity"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Activity"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add New Activity"])},
      "approvalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically approve activity registrations"])},
      "noTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No time slots added. Click \"Add Time Slot\" to begin."])}
    },
    "tabs": {
      "addEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add/Edit Activity"])},
      "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment Activity"])}
    }
  },
  "common": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])}
  },
  "teacherDashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Dashboard"])},
  "teacherDashboard.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your classes and activities"])},
  "activities.searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search activities..."])},
  "activities.statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "activities.noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No activities found matching your criteria."])},
  "activities.clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear Filters"])},
  "activities.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])},
  "activities.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "activities.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "activities.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])},
  "activities.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
  "activities.editTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activity"])},
  "activities.deleteTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete activity"])},
  "activities.addNewTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new activity"])},
  "activities.newActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Activity"])},
  "confirm.deleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Activity"])},
  "confirm.deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this activity?"])},
  "confirm.cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
  "buttons.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "buttons.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "roles.teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
  "roles.assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant Teacher"])},
  "tabs.activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
  "tabs.cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohorts"])},
  "app.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Company Name"])},
  "navigation.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "navigation.myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Calendar"])},
  "cohorts.studentsEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students Enrolled"])},
  "cohorts.mainTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Teachers"])},
  "cohorts.assistantTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistant Teachers"])},
  "cohorts.viewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Details"])},
  "enterCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your credentials to log in"])},
  "schoolLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Logo"])},
  "currentLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Logo"])},
  "noLogoUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No logo uploaded"])},
  "selectLogoFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select logo file"])},
  "logoSizeRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended size: 400x200 pixels, max 2MB"])},
  "uploadLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Logo"])},
  "removeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Logo"])},
  "confirmRemoveLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Logo?"])},
  "removeLogoConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove your school logo? This action cannot be undone."])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "logoUploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo uploaded successfully"])},
  "logoUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload logo"])},
  "logoRemoveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo removed successfully"])},
  "logoRemoveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to remove logo"])},
  "common.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "activity.participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "activity.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "messaging.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])},
  "activity.shareActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Activity"])},
  "activity.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "common.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "activity.registrationDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Deadline"])},
  "activity.nextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Session"])},
  "session.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "session.noReasonProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reason provided"])},
  "activity.loadingNextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading next session..."])},
  "activity.loadingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading activity details..."])},
  "attendance.toggleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to toggle attendance status"])},
  "attendance.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance updated successfully"])},
  "attendance.updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save attendance"])},
  "session.rescheduleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot reschedule. Session data is incomplete"])},
  "session.rescheduleMissingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reschedule session. Session information is missing"])},
  "session.rescheduleSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session rescheduled successfully"])},
  "session.rescheduleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reschedule session"])},
  "session.noSessionToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No session selected to cancel"])},
  "session.cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session canceled successfully"])},
  "session.cancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to cancel session"])},
  "session.uncancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session uncancelled successfully"])},
  "session.uncancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to uncancel session"])},
  "notes.saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note saved successfully"])},
  "notes.saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save note"])},
  "notes.deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note deleted successfully"])},
  "notes.deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete note"])},
  "files.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "attendance.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
  "session.reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reschedule"])},
  "notes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
  "session.uncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncancel"])},
  "session.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "session.confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this session?"])},
  "session.cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for cancellation (optional)"])},
  "session.confirmUncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to uncancel this session?"])},
  "session.cancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Session"])},
  "session.uncancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncancel Session"])},
  "fileUploader": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Files"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])},
    "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "errorFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size must be less than 5MB"])},
    "errorFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only PDF files are allowed"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File uploaded successfully"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload file"])}
  },
  "fileManager": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded Files"])},
    "deleteFileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete File"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this file?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "actionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "downloadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to download file"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File deleted successfully"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete file"])}
  },
  "attendance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
    "upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming Sessions"])},
    "pastSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Sessions"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participants"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rescheduled"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading participants..."])},
    "pdfSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF generated successfully!"])},
    "pdfError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to generate PDF."])},
    "excelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel generated successfully!"])},
    "excelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to generate Excel."])}
  },
  "activityParticipants": {
    "confirmedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed Bookings"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "bookingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Date"])},
    "cohortTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohort Title"])},
    "uniqueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Name"])},
    "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])},
    "slotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slots available"])},
    "noParticipantLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No participant limits set"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "noCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Cohort Assigned"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booked"])},
    "dateUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Unknown"])},
    "dayUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day Unknown"])},
    "startUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Unknown"])},
    "endUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Unknown"])},
    "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Unknown"])},
    "availableSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Spot"])},
    "childDataMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child data missing"])},
    "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No confirmed participants found for this activity."])},
    "waitlistedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waitlisted Bookings"])},
    "pendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Bookings"])},
    "waitlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waitlisted"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Booking"])},
    "bookingApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking approved successfully!"])},
    "bookingApprovalFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to approve booking. Please try again."])},
    "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Activity"])},
    "bookingListFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking List for"])},
    "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Name"])},
    "childName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child Name"])},
    "bookingDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Date & Time"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "fontLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load font for PDF. Please try again."])},
    "pdfGenerationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to generate PDF. Please try again."])}
  },
  "navbar": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Calendar"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Logo"])}
  },
  "admin": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Bookings"])},
    "bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking Approval"])},
    "pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Absence Requests"])},
    "approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approval Center"])},
    "absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absences"])},
    "childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Children Absent"])},
    "viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Absences"])},
    "attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance Reports"])},
    "totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Sessions"])},
    "participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participation Rate"])},
    "liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Booking Status"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIVE"])},
    "activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Promotion"])},
    "spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Spots Available"])},
    "sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reminder"])},
    "noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No open bookings available"])},
    "allAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Absences"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "noAbsencesToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No absences today"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
    "enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrolled"])},
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "noActivitiesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No activities available"])},
    "currentParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Participants"])},
    "waitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting List"])},
    "availableSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Spots"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
    "totalEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Enrolled"])},
    "totalWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Waiting"])},
    "totalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Available"])},
    "occupancyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupancy Rate"])},
    "errorFetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error fetching dashboard data"])},
    "failedToSendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send reminder"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Week"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Month"])},
    "lastQuarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Quarter"])},
    "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Year"])},
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Time"])},
    "customRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Range"])},
    "selectDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Date Range"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "admin.selectBothDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select both a start and end date."])},
  "teacherAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher Absence Notifications"])},
  "teacherAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications when a teacher reports an absence"])},
  "activityBookingReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Booking Reminders"])},
  "activityBookingReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive reminders about activities your child is eligible to join but not yet registered for"])}
}