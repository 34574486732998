export default {
  "Locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
  "systemNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig: Wir führen derzeit Systemwartungen durch, um die Leistung zu verbessern. Während wir unseren Service optimieren, können kurzzeitige Unterbrechungen auftreten. Vielen Dank für Ihr Verständnis."])},
  "activityParticipants.slotTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminzeit"])},
  "activityParticipants.bookingRejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung abgelehnt"])},
  "Teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "Cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorten"])},
  "noFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Filter angewendet"])},
  "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles löschen"])},
  "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles auswählen"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
  "teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "searchLocations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte suchen..."])},
  "searchTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer suchen..."])},
  "searchCohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorten suchen..."])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
  "selectedFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Filter"])},
  "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählt"])},
  "findActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinfachung der Schulverwaltung"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen im SchoolPeak-Portal!"])},
  "navbar.feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
  "userNameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername oder E-Mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Benutzername oder Passwort"])},
  "createAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin erstellen"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
  "remindersManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungsverwaltung"])},
  "settingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "reminderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungsverwaltung"])},
  "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
  "selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
  "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
  "passwordTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Passwort wird vom Benutzer zur Anmeldung verwendet. Stellen Sie sicher, dass es sicher ist. Admins können Passwörter setzen und ändern, aber Benutzer können sie nach der Anmeldung ändern. Admins können Benutzerpasswörter nicht einsehen."])},
  "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
  "noSlotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Slots verfügbar"])},
  "selectTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitslots auswählen"])},
  "recurringDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederkehrende Buchung"])},
  "recurringDialogMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Zeitslot für jede Woche buchen?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
  "subRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerrolle"])},
  "addUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
  "saveDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf speichern"])},
  "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen speichern"])},
  "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil erfolgreich aktualisiert"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "resetPasswordTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein"])},
  "sendResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen senden"])},
  "emailRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail ist erforderlich"])},
  "resetLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen gesendet! Bitte überprüfen Sie Ihre E-Mails."])},
  "resetLinkFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen konnte nicht gesendet werden. Bitte versuchen Sie es erneut."])},
  "emailAndPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail und Passwort sind erforderlich"])},
  "allFieldsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Felder sind erforderlich, um einen Admin zu erstellen"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails"])},
  "publishUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer veröffentlichen"])},
  "secureAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichern Sie Ihr Konto"])},
  "chooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein neues Passwort, um den Zugriff wiederherzustellen"])},
  "resetYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort bestätigen"])},
  "passwordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist erforderlich"])},
  "passwordTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens 8 Zeichen lang sein"])},
  "passwordNumberRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss mindestens eine Zahl enthalten"])},
  "passwordTooWeak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ist zu schwach"])},
  "passwordResetSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde erfolgreich zurückgesetzt"])},
  "passwordResetFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht zurückgesetzt werden"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
  "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Anmeldung"])},
  "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen"])},
  "sessionCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über Sitzungsstornierungen"])},
  "sessionCancellationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über stornierte Sitzungen aktivieren"])},
  "sessionReschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über Sitzungsverschiebungen"])},
  "sessionRescheduleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über verschobene Sitzungen aktivieren"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "preferencesSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen erfolgreich gespeichert"])},
  "profileUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil konnte nicht aktualisiert werden"])},
  "editPaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])},
  "updatePaymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails aktualisieren"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort aktualisieren"])},
  "passwordMismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Passwörter stimmen nicht überein."])},
  "passwordUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich aktualisiert."])},
  "passwordUpdateFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort konnte nicht aktualisiert werden."])},
  "sessionAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung hinzugefügt"])},
  "sessionAddedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Sitzung wurde erfolgreich hinzugefügt."])},
  "sessionUncancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung wiederhergestellt"])},
  "sessionUncancelledDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sitzung wurde wiederhergestellt und wird wie geplant fortgesetzt."])},
  "sessionNotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über Sitzungsnotizen"])},
  "sessionNotesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen erhalten, wenn eine Notiz für eine Sitzung hinzugefügt oder aktualisiert wird."])},
  "sessionAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Sitzung ist bereits gebucht. Bitte wählen Sie eine andere."])},
  "bookingCanceledSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung erfolgreich storniert."])},
  "newMessageNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung über neue Nachrichten"])},
  "newMessageNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Benachrichtigung erhalten, wenn Sie eine neue Nachricht erhalten."])},
  "newReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungsbenachrichtigungen"])},
  "newReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über neue Erinnerungen erhalten, die Ihnen oder der Gruppe Ihres Kindes zugewiesen sind."])},
  "newActivityNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über neue Aktivitäten"])},
  "newActivityNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über neue Aktivitäten erhalten, die für Ihr Kind verfügbar sind."])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auswählen"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "latvian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettisch"])},
  "russian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russisch"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "allActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aktivitäten"])},
  "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
  "locationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standortverwaltung"])},
  "cohortManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenverwaltung"])},
  "calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderansicht"])},
  "whatsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was gibt's Neues"])},
  "extracurricularActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerschulische Aktivitäten"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "filterActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten filtern"])},
  "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort auswählen"])},
  "selectTeacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer auswählen"])},
  "selectCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe auswählen"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "goToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details anzeigen"])},
  "monthView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsansicht"])},
  "weekView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenansicht"])},
  "dayView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesansicht"])},
  "activityApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigungen"])},
  "holidayManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feiertagsverwaltung"])},
  "noPendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit keine ausstehenden Buchungen."])},
  "confirmBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigen"])},
  "confirmBookingMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie ", _interpolate(_named("activityName")), " für ", _interpolate(_named("childName")), " buchen?"])},
  "confirmBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigen"])},
  "doYouWantToBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie buchen"])},
  "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für"])},
  "selectAllChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kinder auswählen"])},
  "paymentDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zu Ihrer Information beträgt der Preis für die Aktivität ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), " (", _interpolate(_named("pricingType")), ")"])},
  "paymentDetailsSlotMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Preis pro Sitzung beträgt ", _interpolate(_named("price")), " ", _interpolate(_named("currency")), ". Die Zahlung wird Ihrer monatlichen Rechnung hinzugefügt."])},
  "commitmentMessageSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich verpflichte mich' klicken, werden die Kosten für die Sitzung Ihrer monatlichen Rechnung hinzugefügt."])},
  "perMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Monat"])},
  "perYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Jahr"])},
  "bookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung bestätigt"])},
  "confirmSlotBookingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot-Buchung bestätigen"])},
  "slotDetailsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte bestätigen Sie die Buchung für den folgenden Slot: Datum: ", _interpolate(_named("date")), ", Startzeit: ", _interpolate(_named("startTime")), ", Endzeit: ", _interpolate(_named("endTime"))])},
  "slotBookingConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slot-Buchung bestätigt!"])},
  "slotAlreadyBookedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Slot ist bereits gebucht. Bitte wählen Sie eine andere verfügbare Zeit."])},
  "activityBookedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Aktivität '", _interpolate(_named("activity")), "' wurde erfolgreich gebucht."])},
  "quarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vierteljährlich"])},
  "biAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halbjährlich"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesamt"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "iCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich verpflichte mich"])},
  "myProfileData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Profildaten"])},
  "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
  "currentActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Aktivitäten"])},
  "upcomingActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommende Aktivitäten"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiter"])},
  "commitmentConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verpflichtungsbestätigung"])},
  "commitmentMessagePerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich verpflichte mich' klicken, werden die Kosten für die Aktivität Ihrer monatlichen Rechnung für die nächsten 3 Monate hinzugefügt."])},
  "commitmentMessagePerYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich verpflichte mich' klicken, werden die Kosten für die Aktivität Ihrer jährlichen Rechnung hinzugefügt."])},
  "commitmentMessageQuarterly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich verpflichte mich' klicken, werden die Kosten für die Aktivität in vierteljährliche Zahlungen aufgeteilt."])},
  "commitmentMessageBiAnnual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich verpflichte mich' klicken, werden die Kosten für die Aktivität in halbjährliche Zahlungen aufgeteilt."])},
  "commitmentMessageTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie auf 'Ich verpflichte mich' klicken, wird der Gesamtbetrag der Aktivität sofort belastet."])},
  "filterByChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Kind filtern"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut."])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird verarbeitet..."])},
  "activityBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität erfolgreich gebucht, wartet auf Genehmigung."])},
  "confirmCancellationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Buchung für ", _interpolate(_named("activityName")), " für ", _interpolate(_named("childName")), " stornieren?"])},
  "confirmCancellationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornierung bestätigen"])},
  "confirmAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion bestätigen"])},
  "confirmActionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie fortfahren möchten?"])},
  "bookingCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung storniert."])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitslots"])},
  "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
  "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilen pro Seite"])},
  "roleManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenverwaltung"])},
  "welcomeParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "viewActivitiesOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier sehen Sie die Aktivitäten von"])},
  "noUpcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bevorstehenden Sitzungen"])},
  "nextEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre nächsten Veranstaltungen"])},
  "openBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre offenen Buchungen"])},
  "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind:"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung stornieren"])},
  "confirmCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stornierung bestätigen"])},
  "cohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planer"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])},
  "selectReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund auswählen"])},
  "writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund angeben"])},
  "reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier Ihren Grund ein..."])},
  "sickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankheit"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "selectView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht auswählen"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "activity.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "activity.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "activity.dateBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsdatum"])},
  "filterActivities.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten filtern"])},
  "filterActivities.all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Aktivitäten"])},
  "filterActivities.past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Aktivitäten"])},
  "filterActivities.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Aktivitäten"])},
  "filterActivities.future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zukünftige Aktivitäten"])},
  "filterActivities.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "filterActivities.selectFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter auswählen"])},
  "searchModal.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "searchModal.placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Namen suchen"])},
  "searchModal.reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "searchModal.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "activityDetail.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "activityDetail.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
  "activityDetail.files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "activityDetail.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "activityDetail.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
  "activityDetail.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "activityDetail.upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehende Sitzungen"])},
  "activityDetail.previousSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Sitzungen"])},
  "activityDetail.loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Aktivitätsdetails..."])},
  "sessionStatus.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgesagt"])},
  "sessionStatus.rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschoben"])},
  "sessionStatus.scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplant"])},
  "sessionStatus.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "navbar.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "navbar.planner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planer"])},
  "navbar.bookedActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hub"])},
  "navbar.calendarView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender"])},
  "allChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kinder"])},
  "selectChild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind auswählen"])},
  "noActivitiesForChild": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Derzeit sind keine Aktivitäten für ", _interpolate(_named("childName")), " verfügbar. Bitte schauen Sie später wieder vorbei."])},
  "navbar.reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen"])},
  "navbar.messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "navbar.notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "navbar.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "reminders.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Erinnerungen"])},
  "reminders.excludeOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alte Erinnerungen ausschließen"])},
  "reminders.table.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "reminders.table.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "reminders.table.dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeitsdatum"])},
  "reminders.fetchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen konnten nicht geladen werden"])},
  "reminders.untitled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Titel"])},
  "reminders.noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Beschreibung"])},
  "reminders.invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Datum"])},
  "bulkDelete.confirmationMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie wirklich ", _interpolate(_named("count")), " Benutzer löschen?"])},
  "bulkDelete.cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "bulkDelete.deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "reasonForExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Abwesenheit"])},
  "excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "excused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesend"])},
  "excuseForDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abwesenheit von ", _interpolate(_named("name")), " für einen Tag melden"])},
  "confirmExcuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit bestätigen"])},
  "confirmExcuseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Abwesenheit dieses Schülers für den ausgewählten Tag melden?"])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit erfolgreich für den ausgewählten Tag gemeldet."])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit konnte nicht gemeldet werden. Bitte versuchen Sie es erneut."])},
  "warningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein Datum und einen gültigen Grund an."])},
  "activityDetail.excuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "activityDetail.revertExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit rückgängig machen"])},
  "activityDetail.excuseSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit für Sitzung melden"])},
  "activityDetail.enterReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund angeben"])},
  "activityDetail.writeReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund angeben"])},
  "activityDetail.reasonPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier Ihren Grund ein..."])},
  "activityDetail.reasonSickLeave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankheit"])},
  "activityDetail.reasonOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])},
  "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannte Aktivität"])},
  "unknownLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Standort"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kalender hinzufügen"])},
  "reportAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "revertAbsence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit rückgängig machen"])},
  "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filteroptionen"])},
  "excuseForRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit melden"])},
  "selectTimeRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum auswählen"])},
  "excuseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheitsdatum"])},
  "excuseSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheit erfolgreich gemeldet."])},
  "noBookingsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Buchungen gefunden. Entdecken Sie Aktivitäten im Planer und buchen Sie jetzt!"])},
  "activityDetail.calendarInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um diesen Kalender zu Google Calendar hinzuzufügen: Kopieren Sie den bereitgestellten Link. Öffnen Sie dann Google Calendar, klicken Sie auf das '+' Symbol neben 'Andere Kalender', wählen Sie 'Von URL', fügen Sie den Link in das Feld 'URL des Kalenders' ein und klicken Sie auf 'Kalender hinzufügen'."])},
  "activityDetail.linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalenderlink in die Zwischenablage kopiert!"])},
  "activityDetail.copyFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link konnte nicht kopiert werden. Bitte kopieren Sie ihn manuell."])},
  "activityDetail.openIcsLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICS-Link öffnen"])},
  "activityDetail.importCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalender importieren"])},
  "activityDetail.addToCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zum Kalender hinzufügen"])},
  "paymentDetailsUnavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails sind derzeit nicht verfügbar."])},
  "childAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung über Abwesenheit des Kindes"])},
  "childAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen erhalten, wenn ein Kind als abwesend markiert ist."])},
  "childSessionAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigung über Abwesenheit des Kindes in einer Sitzung"])},
  "childSessionAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen erhalten, wenn ein Kind für eine bestimmte Sitzung als abwesend markiert ist."])},
  "allDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "admin.bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
  "admin.pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
  "admin.newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu (7d)"])},
  "admin.bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Buchungsgenehmigung"])},
  "admin.absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Abwesenheiten"])},
  "admin.childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Kinder"])},
  "admin.attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheitsberichte"])},
  "admin.totalParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Teilnehmer:"])},
  "admin.attendanceRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheitsrate:"])},
  "admin.childrenExcused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesende Kinder:"])},
  "admin.noReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anwesenheitsberichte verfügbar."])},
  "admin.liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live-Buchungsstatus"])},
  "admin.activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsförderung"])},
  "admin.spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Plätze verfügbar"])},
  "admin.sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung senden"])},
  "admin.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "status.upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevorstehend"])},
  "status.completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "status.ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufend"])},
  "admin.noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derzeit keine offenen Buchungen."])},
  "admin.pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehende Abwesenheitsanfragen"])},
  "admin.approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Abwesenheitsgenehmigung"])},
  "admin.viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Abwesenheiten anzeigen"])},
  "admin.totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Sitzungen"])},
  "admin.participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmerate"])},
  "wholeDayExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganztägig"])},
  "enterCommentForOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund angeben"])},
  "excuseReverted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung zurückgenommen"])},
  "activityDetail.confirmedExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung bestätigt"])},
  "activityDetail.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung erfolgreich"])},
  "activityDetail.pendingExcuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehende Entschuldigung"])},
  "sessionExcuseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zur Unterrichtsbefreiung"])},
  "studentAbsenceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über Schülerabwesenheit"])},
  "studentAbsenceNotificationsDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Benachrichtigungen erhalten, wenn ein Schüler als abwesend markiert wird"])},
  "preferencesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen konnten nicht gespeichert werden"])},
  "failedToLoadPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen konnten nicht geladen werden"])},
  "manageYourAccountPreferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Kontoeinstellungen"])},
  "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoeinstellungen"])},
  "updateYourPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie Ihr aktuelles Passwort"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
  "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präferenzen"])},
  "changeDisplayLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern Sie die Anzeigesprache"])},
  "manageNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie, wie und wann Sie Benachrichtigungen erhalten"])},
  "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten"])},
  "aboutApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über die App"])},
  "checkLatestFeatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie die neuesten Funktionen und Updates"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "updateYourProfileInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie Ihre Profilinformationen"])},
  "schoolPolicies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulrichtlinien"])},
  "schoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulinformationen"])},
  "viewSchoolInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulinformationen anzeigen"])},
  "accessSchoolPoliciesAndDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Schulrichtlinien und Informationen zugreifen"])},
  "schoolInfoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfolgend finden Sie Informationen zu den Richtlinien Ihrer Schule"])},
  "activities": {
    "form": {
      "basicDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundlegende Details"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termine"])},
      "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
      "cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorten"])},
      "autoApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Genehmigung"])},
      "timeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster"])},
      "addTimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitfenster hinzufügen"])},
      "saveAsDraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Entwurf speichern"])},
      "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität veröffentlichen"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität wird verarbeitet"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität bearbeiten"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aktivität hinzufügen"])},
      "approvalLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsanmeldungen automatisch genehmigen"])},
      "noTimeSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Zeitfenster hinzugefügt. Klicken Sie auf \"Zeitfenster hinzufügen\", um zu beginnen."])}
    },
    "tabs": {
      "addEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität hinzufügen/bearbeiten"])},
      "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminaktivität"])}
    }
  },
  "common": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])}
  },
  "teacherDashboard.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer-Dashboard"])},
  "teacherDashboard.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Ihre Klassen und Aktivitäten"])},
  "activities.searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten suchen..."])},
  "activities.statusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "activities.noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktivitäten gefunden, die Ihren Kriterien entsprechen."])},
  "activities.clearFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])},
  "activities.draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
  "activities.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
  "activities.endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum"])},
  "activities.schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitplan"])},
  "activities.teachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "activities.editTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität bearbeiten"])},
  "activities.deleteTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität löschen"])},
  "activities.addNewTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aktivität hinzufügen"])},
  "activities.newActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aktivität"])},
  "confirm.deleteTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität löschen"])},
  "confirm.deleteQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Aktivität löschen möchten?"])},
  "confirm.cannotUndo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion kann nicht rückgängig gemacht werden."])},
  "buttons.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "buttons.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "roles.teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "roles.assistant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfslehrer"])},
  "tabs.activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten"])},
  "tabs.cohorts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohorten"])},
  "app.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Firmenname"])},
  "navigation.overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersicht"])},
  "navigation.myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Kalender"])},
  "cohorts.studentsEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeschriebene Schüler"])},
  "cohorts.mainTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptlehrer"])},
  "cohorts.assistantTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfslehrer"])},
  "cohorts.viewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details anzeigen"])},
  "enterCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre Anmeldedaten ein"])},
  "schoolLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schullogo"])},
  "currentLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Logo"])},
  "noLogoUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Logo hochgeladen"])},
  "selectLogoFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logodatei auswählen"])},
  "logoSizeRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlene Größe: 400x200 Pixel, max. 2MB"])},
  "uploadLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo hochladen"])},
  "removeLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo entfernen"])},
  "confirmRemoveLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo entfernen?"])},
  "removeLogoConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie Ihr Schullogo entfernen möchten? Diese Aktion kann nicht rückgängig gemacht werden."])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "logoUploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo erfolgreich hochgeladen"])},
  "logoUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Hochladen des Logos"])},
  "logoRemoveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo erfolgreich entfernt"])},
  "logoRemoveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Entfernen des Logos"])},
  "common.about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
  "activity.participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
  "activity.sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
  "messaging.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])},
  "activity.shareActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität teilen"])},
  "activity.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "common.to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
  "activity.registrationDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldefrist"])},
  "activity.nextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Sitzung"])},
  "session.cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgesagt"])},
  "session.noReasonProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Grund angegeben"])},
  "activity.loadingNextSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Sitzung wird geladen..."])},
  "activity.loadingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsdetails werden geladen..."])},
  "attendance.toggleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung des Anwesenheitsstatus fehlgeschlagen"])},
  "attendance.updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheit erfolgreich aktualisiert"])},
  "attendance.updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern der Anwesenheit fehlgeschlagen"])},
  "session.rescheduleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umplanung nicht möglich. Sitzungsdaten sind unvollständig"])},
  "session.rescheduleMissingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umplanung der Sitzung fehlgeschlagen. Sitzungsinformationen fehlen"])},
  "session.rescheduleSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung erfolgreich umgeplant"])},
  "session.noSessionToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sitzung zum Stornieren ausgewählt"])},
  "session.cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung erfolgreich abgesagt"])},
  "session.cancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung konnte nicht abgesagt werden"])},
  "session.uncancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absage der Sitzung erfolgreich rückgängig gemacht"])},
  "session.uncancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absage der Sitzung konnte nicht rückgängig gemacht werden"])},
  "notes.saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz erfolgreich gespeichert"])},
  "notes.saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz konnte nicht gespeichert werden"])},
  "notes.deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz erfolgreich gelöscht"])},
  "notes.deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz konnte nicht gelöscht werden"])},
  "files.download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "attendance.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheit"])},
  "session.reschedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschieben"])},
  "notes.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
  "session.uncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absage aufheben"])},
  "session.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absagen"])},
  "session.confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Sitzung absagen möchten?"])},
  "session.cancellationReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund für die Absage (optional)"])},
  "session.confirmUncancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Absage dieser Sitzung rückgängig machen möchten?"])},
  "session.cancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzung absagen"])},
  "session.uncancelSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absage rückgängig machen"])},
  "fileUploader": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochladen"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen"])},
    "uploadBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "errorFileSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dateigröße muss kleiner als 5 MB sein"])},
    "errorFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur PDF-Dateien sind erlaubt"])},
    "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei erfolgreich hochgeladen"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Hochladen der Datei"])}
  },
  "fileManager": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochgeladene Dateien"])},
    "deleteFileTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei löschen"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Datei löschen möchten?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "actionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "downloadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Herunterladen der Datei"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei erfolgreich gelöscht"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Löschen der Datei"])}
  },
  "attendance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheit"])},
    "upcomingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommende Sitzungen"])},
    "pastSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergangene Sitzungen"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgesagt"])},
    "rescheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschoben"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notiz"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer werden geladen..."])},
    "pdfSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF erfolgreich generiert!"])},
    "pdfError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Generieren des PDF."])},
    "excelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel erfolgreich generiert!"])},
    "excelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Generieren der Excel-Datei."])}
  },
  "activityParticipants": {
    "confirmedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigte Buchungen"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "bookingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsdatum"])},
    "cohortTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohortenname"])},
    "uniqueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutiger Name"])},
    "downloadPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])},
    "slotsAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plätze verfügbar"])},
    "noParticipantLimits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teilnehmerbegrenzung festgelegt"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
    "noCohort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Kohorte zugewiesen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebucht"])},
    "dateUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum unbekannt"])},
    "dayUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag unbekannt"])},
    "startUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit unbekannt"])},
    "endUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit unbekannt"])},
    "locationUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort unbekannt"])},
    "availableSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbarer Platz"])},
    "childDataMissing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinddaten fehlen"])},
    "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bestätigten Teilnehmer für diese Aktivität gefunden."])},
    "waitlistedBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warteliste Buchungen"])},
    "pendingBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehende Buchungen"])},
    "waitlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Warteliste"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigen"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung hinzufügen"])},
    "bookingApproved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchung erfolgreich genehmigt!"])},
    "bookingApprovalFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Genehmigen der Buchung. Bitte versuchen Sie es erneut."])},
    "unknownActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannte Aktivität"])},
    "bookingListFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsliste für"])},
    "activityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsname"])},
    "childName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Kindes"])},
    "bookingDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsdatum & -zeit"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "fontLoadingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Laden der Schriftart für PDF. Bitte versuchen Sie es erneut."])},
    "pdfGenerationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Generieren des PDF. Bitte versuchen Sie es erneut."])}
  },
  "navbar": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "myCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Kalender"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenlogo"])}
  },
  "perMonthPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(monatliches Abonnement, gleicher Preis unabhängig von der Anzahl der ausgewählten Sitzungen)"])},
  "perYearPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(jährliches Abonnement, gleicher Preis unabhängig von der Anzahl der ausgewählten Sitzungen)"])},
  "quarterlyPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(vierteljährliches Abonnement, gleicher Preis unabhängig von der Anzahl der ausgewählten Sitzungen)"])},
  "biAnnualPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(halbjährliches Abonnement, gleicher Preis unabhängig von der Anzahl der ausgewählten Sitzungen)"])},
  "perSessionPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Preis pro einzelne Sitzung)"])},
  "totalPriceNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(einmalige Gesamtzahlung)"])},
  "selectedSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Sitzungen"])},
  "selectRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle auswählen"])},
  "multipleRolesMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben mehrere Rollen. Bitte wählen Sie, welche Rolle Sie verwenden möchten."])},
  "administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
  "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehrer"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elternteil"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "activityParticipants.by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "activityParticipants.approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigt"])},
  "adminDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten Sie Benutzer, Aktivitäten und Systemeinstellungen"])},
  "teacherDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Aktivitäten und verwalten Sie Ihre Schüler"])},
  "parentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie Aktivitäten für Ihre Kinder und sehen Sie deren Zeitplan"])},
  "childDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie Ihre Aktivitäten und Ihren Zeitplan"])},
  "switchRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle wechseln"])},
  "switchToRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu einer anderen Rolle wechseln"])},
  "selectRoleToSwitchTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie, welche Rolle Sie verwenden möchten:"])},
  "monthlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Monatspreis deckt alle ", _interpolate(_named("count")), " ausgewählten Sitzungen ab"])},
  "yearlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jahrespreis deckt alle ", _interpolate(_named("count")), " ausgewählten Sitzungen ab"])},
  "quarterlyPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vierteljährlicher Preis deckt alle ", _interpolate(_named("count")), " ausgewählten Sitzungen ab"])},
  "biAnnualPriceWithMultipleSlots": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Halbjährlicher Preis deckt alle ", _interpolate(_named("count")), " ausgewählten Sitzungen ab"])},
  "admin": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungen"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehend"])},
    "newBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Buchungen"])},
    "bookingApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchungsfreigabe"])},
    "pendingExcuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehende Abwesenheitsanträge"])},
    "approvalCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigungszentrum"])},
    "absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abwesenheiten"])},
    "childrenAbsent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Kinder abwesend"])},
    "viewAllAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Abwesenheiten anzeigen"])},
    "attendanceReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheitsberichte"])},
    "totalSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtsitzungen"])},
    "participationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnahmequote"])},
    "liveBookingStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live-Buchungsstatus"])},
    "live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIVE"])},
    "activityPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsförderung"])},
    "spotsAvailable": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Plätze verfügbar"])},
    "sendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung senden"])},
    "noOpenBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine offenen Buchungen verfügbar"])},
    "allAbsences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Abwesenheiten"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "noAbsencesToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute keine Abwesenheiten"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapazität"])},
    "enrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angemeldet"])},
    "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warteliste"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbar"])},
    "noActivitiesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aktivitäten verfügbar"])},
    "currentParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Teilnehmer"])},
    "waitingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warteliste"])},
    "availableSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfügbare Plätze"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten"])},
    "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer"])},
    "totalEnrolled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt angemeldet"])},
    "totalWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt wartend"])},
    "totalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt verfügbar"])},
    "occupancyRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belegungsrate"])},
    "errorFetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Abrufen von Daten"])},
    "failedToSendReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung konnte nicht gesendet werden"])},
    "lastWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Woche"])},
    "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Monat"])},
    "lastQuarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Quartal"])},
    "lastYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letztes Jahr"])},
    "allTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamter Zeitraum"])},
    "customRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefinierter Zeitraum"])},
    "selectDateRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumsbereich auswählen"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
  },
  "admin.selectBothDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie ein Start- und ein Enddatum aus."])},
  "teacherAbsenceNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen über Lehrerabwesenheit"])},
  "teacherAbsenceNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Benachrichtigungen, wenn ein Lehrer eine Abwesenheit meldet"])},
  "activityBookingReminderNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsbuchungserinnerungen"])},
  "activityBookingReminderNotificationDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie Erinnerungen zu Aktivitäten, für die Ihr Kind teilnahmeberechtigt ist, aber noch nicht angemeldet wurde"])}
}