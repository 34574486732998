// app/comonents/services/authService.js
import API from './api';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useGtm } from '@gtm-support/vue-gtm';

const AuthService = {
  getTenantHost() {
    const hostname_parts = window.location.hostname.split('.').reverse();
    if (hostname_parts.length >= 2 && hostname_parts[1] === 'schoolpeak') {
      return hostname_parts[2];
    } else {
      return 'tenant1';
    }
  },
  async login(email, password) {
    if (!email || !password) {
      throw new Error('Email and password are required');
    }
  
    try {
      localStorage.clear();
      sessionStorage.clear();
  
      const gtm = useGtm();
      const tenantId = this.getTenantHost();
      const response = await API.post('auth/login', {
        email,
        password,
        tenantId,
      });
  
      if (!response.data._id || !response.data.accessToken || !response.data.refreshToken || !response.data.roles) {
        throw new Error('Incomplete response from server');
      }
  
      // Using main role names (admin, teacher, parent, child) rather than subRoles
      const roles = response.data.roles.map(role => role.name || role);
      const roleIds = response.data.roleIds || [];
      const childRoleId = response.data.childRoleId;
  
      if (response.data.lastLogin) {
        localStorage.setItem('last-login', response.data.lastLogin);
      }
  
      localStorage.setItem('user-token', response.data.accessToken);
      localStorage.setItem('user-refresh-token', response.data.refreshToken);
      localStorage.setItem('user-roles', JSON.stringify(roles));
      localStorage.setItem('user-id', response.data._id);
  
      // Store all role IDs but don't set a primary one yet if there are multiple roles
      localStorage.setItem('role-ids', JSON.stringify(roleIds));
      
      // If there's only one role, use it as the active role
      if (roleIds.length === 1) {
        localStorage.setItem('role-id', roleIds[0]);
        localStorage.setItem('active-role', roles[0]);
      }
      
      localStorage.setItem('child-role-id', childRoleId);
  
      if (roles.some((role) => role === 'parent')) {
        localStorage.setItem('parent-id', response.data._id);
      }
  
      gtm.trackEvent({
        event: 'login',
        user_id: response.data._id,
        tenant_id: tenantId,
      });
  
      return { roles, roleIds, childRoleId };
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || 'Login failed');
      } else {
        throw new Error('Network error or server is not responding');
      }
    }
  }, 
  async register(
    username,
    email,
    password,
    roles,
    firstName,
    lastName,
    address,
    phone
  ) {
    if (
      !username ||
      !email ||
      !password ||
      !roles ||
      !firstName ||
      !lastName ||
      !address ||
      !phone
    ) {
      throw new Error('All fields are required');
    }

    try {
      const tenantId = this.getTenantHost();
      const response = await API.post('auth/register', {
        username,
        email,
        password,
        roles,
        firstName,
        lastName,
        address,
        phone,
        tenantId,
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error.response.data.message || 'Registration failed');
      } else {
        throw new Error('Network error or server is not responding');
      }
    }
  },
  
  // Set the active role for a user with multiple roles
  setActiveRole(roleId, roleName) {
    localStorage.setItem('role-id', roleId);
    localStorage.setItem('active-role', roleName);
    
    // If the role is parent, make sure to set the parent ID
    if (roleName === 'parent') {
      localStorage.setItem('parent-id', this.getUserId());
    }
    
    return { roleId, roleName };
  },
  
  // Get the current active role
  getActiveRole() {
    return localStorage.getItem('active-role');
  },
  
  // Get the current active role ID
  getActiveRoleId() {
    return localStorage.getItem('role-id');
  },
  
  getLastLogin() {
    return localStorage.getItem('last-login');
  },

  logout() {
    localStorage.removeItem('user-token');
    localStorage.removeItem('user-refresh-token');
    localStorage.removeItem('user-roles');
    localStorage.removeItem('user-id');
    localStorage.removeItem('parent-id');
    localStorage.removeItem('role-ids');
    localStorage.removeItem('role-id');
    localStorage.removeItem('active-role');
    localStorage.removeItem('child-role-id');
  },  

  getToken() {
    return localStorage.getItem('user-token');
  },

  isTokenValid(token) {
    if (!token) return false;
    const decoded = jwtDecode(token);
    return decoded.exp > Date.now() / 1000;
  },

  getUserId() {
    return localStorage.getItem('user-id');
  },

  getParentId() {
    return localStorage.getItem('parent-id');
  },

  getRoles() {
    const roles = localStorage.getItem('user-roles');
    return roles ? JSON.parse(roles) : [];
  },

  getRoleIds() {
    const roleIds = localStorage.getItem('role-ids');
    try {
      if (!roleIds) {
        console.warn('Role IDs not found in localStorage or are undefined.');
        return [];
      }

      const parsedRoleIds = JSON.parse(roleIds);
      if (!Array.isArray(parsedRoleIds)) {
        console.error('Parsed role IDs are not an array:', parsedRoleIds);
        return [];
      }

      return parsedRoleIds;
    } catch (error) {
      console.error('Error parsing role IDs from localStorage:', error.message);
      return [];
    }
  },

  async sendResetPasswordEmail(email, tenantId) {
    if (!email || !tenantId) {
      throw new Error('Email and tenant ID are required');
    }

    try {
      await API.post('auth/reset-password/send', { email, tenantId });
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(
          error.response.data.message || 'Failed to send reset password email'
        );
      } else {
        throw new Error('Network error or server is not responding');
      }
    }
  },
  async refreshTokens() {
    const refreshToken = localStorage.getItem('user-refresh-token');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }
    try {
      const response = await API.post('auth/refresh', { refreshToken });
      if (!response.data.accessToken || !response.data.refreshToken) {
        throw new Error('Incomplete response from refresh endpoint');
      }
      localStorage.setItem('user-token', response.data.accessToken);
      localStorage.setItem('user-refresh-token', response.data.refreshToken);
      console.log('Tokens refreshed successfully');
      return response.data;
    } catch (error) {
      console.error('Failed to refresh tokens:', error);
      throw error;
    }
  },
  resetPassword(token, password) {
    return axios.post('/api/auth/reset-password/reset', { token, password });
  },

  getChildRoleId() {
    return localStorage.getItem('child-role-id');
  },
};

export default AuthService;