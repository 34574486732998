// main.js

(function() {
  const originalAddEventListener = EventTarget.prototype.addEventListener;
  EventTarget.prototype.addEventListener = function(type, listener, options) {
    if (type === 'touchstart') {
      let newOptions;
      if (options === undefined || typeof options === 'boolean') {
        newOptions = { passive: true };
      } else if (typeof options === 'object' && options !== null) {
        newOptions = Object.assign({}, options, { passive: true });
      }
      return originalAddEventListener.call(this, type, listener, newOptions);
    }
    return originalAddEventListener.call(this, type, listener, options);
  };
})();

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;
import '@quasar/extras/material-icons/material-icons.css';
import 'quasar/dist/quasar.css';
import './styles/quasar.scss';
import quasarUserOptions from './quasar-user-options';
import { createI18n } from 'vue-i18n';
import store from './store';
import eventBus from './eventBus';
import UnauthorizedError from '@/components/UnauthorizedError.vue';
import axios from 'axios';
import AuthService from './services/authService';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import { createGtm } from '@gtm-support/vue-gtm';

import en from '@/locales/en.json';
import de from '@/locales/de.json';
import lv from '@/locales/lv.json';
import ru from '@/locales/ru.json';
import VueGoogleMaps from '@fawmi/vue-google-maps';

import QCalendar from '@quasar/quasar-ui-qcalendar';
import '@quasar/quasar-ui-qcalendar/dist/index.css';

import { io } from 'socket.io-client';

const messages = {
  en,
  de,
  lv,
  ru,
};

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    const ctx = this;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
  constructor(callback) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

const app = createApp(App);
app.use(store);
app.use(router);
app.use(
  createGtm({
    id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    queryParams: {
      // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      gtm_auth: process.env.VUE_APP_GTM_AUTH,
      gtm_preview: process.env.VUE_APP_GTM_PREVIEW,
      // gtm_cookies_win: process.env.GTM_COOKIES_WIN,
    },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: false,
    debug: process.env.NODE_ENV != 'production', // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
);
app.use(i18n);
app.use(Quasar, {
  plugins: { Notify, Dialog },
  ...quasarUserOptions,
});

app.use(VueGoogleMaps, {
  load: {
    key: 'YOUR_GOOGLE_MAPS_API_KEY',
    libraries: 'places',
  },
});
app.use(QCalendar);

app.component('UnauthorizedError', UnauthorizedError);

app.config.globalProperties.$eventBus = eventBus;

const socket = io(process.env.VUE_APP_SOCKET_URL || 'https://localhost:5005');
app.config.globalProperties.$socket = socket;

app.config.globalProperties.$axios = axios;

app.mount('#app');

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message ===
        'Access denied. You do not have the required permissions.'
    ) {
      Notify.create({
        type: 'negative',
        message:
          'You do not have the permission to do this. Please contact the administrator.',
      });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken(); // Retrieve the token from AuthService
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Add token to Authorization header
    } else {
      console.warn(
        'No token found in localStorage. Authorization header not added.'
      );
    }
    return config;
  },
  (error) => {
    // Do something with the request error
    return Promise.reject(error);
  }
);

console.log('API Base URL:', process.env.VUE_APP_API_BASE_URL);
